<template>
  <div class="ps-page--single ps-page--vendor">
    <section class="ps-store-list">
      <div class="ps-container">
        <form class="ls-form">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
              <div class="form-group mt-3">
                <label for="shop">Select Shop</label>
                <select class="form-control" id="shop" @change="getVouchers($event)" v-model="shop">
                  <option :value="null">All</option>
                  <option v-for="shop_ in shops" :value="shop_.id" :key="shop_.id">
                    {{ shop_.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
              <div class="form-group">
                <h2>Buy and Send Voucher</h2>
                <!--                <div class="mb-2 row flex">-->
                <!--                  <div class="ps-shopping__actions align-content-end">-->
                <!--                    <strong> {{ vouchers.length }}</strong><br />&nbsp;&nbsp;Vouchers found-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="row ls-bundles-container" v-if="totalVouchers > 0">
                  <div class="col-xl-3 col-lg-4 col-md-6 col-12 equal-height" v-for="voucher in vouchers"
                    :key="voucher.id">
                    <Voucher :voucher="voucher"></Voucher>
                  </div>
                </div>
                <div class="row ls-bundles-container" v-else>
                  <p class="text-center p-5 text-danger">No vouchers found.</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Voucher from "../Vouchers/Voucher";
import categories from "../../../components/Navs/Categories.vue";


export default {
  name: "Vouchers",
  components: { Voucher },
  data() {
    return {
      shops: [],
      shop: null,
      vouchers: [],
      totalVouchers: 0,
      voucherData: {},
      isLoading: false,
      category: null,
      categories: [],
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  watch: {
    $route(to, from) {
      this.getVouchers();
    },
  },
  mounted() {
    this.getCategories();
    this.getShops();
    this.getVouchers();
  },
  methods: {
    filterVouchers($event) { },

    viewVoucher(voucher) {
      this.voucher = voucher;
    },

    getCategories() {
      axios.get("/vouchers/categories").then((response) => {
        this.categories = response.data;
      });
    },

    getShops() {
      axios.get("/shops/list?with_vouchers=1").then((response) => {
        this.shops = response.data;
      });
    },

    getVouchers() {
      let params = ["status=active"];
      if (this.shop) {
        params.push(`shop=${this.shop}`);
      }
      if (this.category) {
        params.push(`category=${this.category}`);
      }
      this.isLoading = true;
      axios.get(`vouchers?${params.join("&")}`).then((response) => {
        this.vouchers = response.data.data;
        this.totalVouchers = response.data.total;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
