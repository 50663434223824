<template>
  <div class="ls-product-card">
    <router-link
      :to="{ name: 'voucher_detail', params: { slug: voucher.slug } }"
    >
      <div class="row">
        <div class="col-12"><img v-lazy="voucher.image_path" /></div>
        <div class="col-12">
          <a class="ps-product__vendor">
            {{ voucher.name }}
          </a>
        </div>
        <div class="col-12">
          <router-link :to="'/shop/' + voucher.merchant.slug"
            ><h4>{{ voucher.merchant.name }}</h4></router-link
          >
        </div>
        <div class="col-12">
          <router-link
            :to="{ name: 'voucher_detail', params: { slug: voucher.slug } }"
          >
            <button class="btn ls-button">View</button>
          </router-link>
        </div>
      </div>
    </router-link>

    <div class="ps-product" style="display: none">
      <div class="ps-product__thumbnail">
        <router-link :to="'/vouchers/' + voucher.slug">
          <img v-lazy="voucher.image_path" alt="Product Image" />
        </router-link>
      </div>
      <div class="ps-product__container">
        <router-link class="ps-product__vendor" :to="'/shop/' + voucher.slug">{{
          voucher.name
        }}</router-link>
        <div class="ps-product__content">
          <router-link
            :to="'/vouchers/' + voucher.slug"
            class="ps-product__title"
            >{{ voucher.name }}</router-link
          >
          <div class="ps-product__rating"></div>
          <p class="ps-product__price sale">
            {{ voucher.amounts[0].amount | toCurrency }}
          </p>
        </div>
        <div class="ps-product__content hover">
          <router-link
            :to="'/vouchers/' + voucher.slug"
            class="ps-product__title"
            >{{ voucher.name }}</router-link
          >
          <p class="ps-product__price sale">
            {{ voucher.amounts[0].amount | toCurrency }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Voucher",
  props: {
    voucher: Object,
  },
  data() {
    return {
      form: {
        mobile: "",
        full_name: "",
        email: "",
      },
      imagePath: "",
      isLoading: false,
      agreeToTerms: false,
      isBusy: false,
      errors: {},
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {},
  methods: {
    openVoucherDetailsModal() {
      this.$refs[`BuyVoucher${this.voucher.id}`].show();
    },

    closeModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs[`BuyVoucher${this.voucher.id}`].hide();
    },

    isValid() {
      let isValid = true;
      if (this.form.mobile === "") {
        this.errors["mobile"] = "This field is required";
        isValid = false;
      }
      if (this.form.full_name === "") {
        this.errors["full_name"] = "This field is required";
        isValid = false;
      }
      if (!this.agreeToTerms) {
        this.errors["terms"] = "Please agree to terms";
        isValid = false;
      }

      return isValid;
    },

    addToCart() {
      if (this.isValid()) {
        const price = this.voucher.amount;
        const quantity = 1;
        const total = price * quantity;

        const product = {
          id: this.voucher.id,
          name: this.voucher.name,
          quantity: quantity,
          price: this.voucher.amount,
          total: total,
          type: "voucher",
          shop: 2,
          props: { ...this.form, description: this.voucher.description },
        };

        console.log("Voucher");
        console.log(product);
        this.$store.dispatch("cart/addToCart", {
          product: product,
          quantity: 1,
        });
        //this.$refs[`BuyVoucher${this.voucher.id}`].hide();
        this.$toasted.success("Voucher added to cart");
      }
    },
  },
};
</script>
